import React, { Fragment } from 'react'
import Ad from 'ion-ads'
import FORMATS from '../../components/AdFormats'
import ArticleCard from '../../components/ArticleCard'
import LazyLoad from 'react-lazyload'

const Default = ({ articles, section, noImage, isConnected }) => {
  return articles.map((article, index) => {
    return (
      <Fragment key={article.contentKey}>
        {index <= 9 && (
          <ArticleCard article={article} noImage={noImage} />
        )}
        {index > 9 && (
          <LazyLoad height={320}>
            <ArticleCard article={article} noImage={noImage} />
          </LazyLoad>
        )}
        {index === 8 && (
          <Ad isConnected={isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/homepage`} targeting={{ leaderboard: 'top2' }} collapseEmptyDiv className='advert-leaderboard-top' {...FORMATS.leaderboard} />
        )}
      </Fragment>
    )
  })
}

export default Default
