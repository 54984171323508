import React, { Component } from 'react'
import { Sticky } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import 'semantic-ui-css/components/sticky.min.css'
// import JoinDirectory from '../static/general/join-our-design-directory.jpg'
import Ad from 'ion-ads'
import FORMATS from './AdFormats'
// import { DesktopTablet, Mobile } from 'ion-media'
import { Desktop, Tablet } from 'ion-media'
import { fetchMagazineCover } from '../../store/magazineCover'
import { Search } from 'ion-icon'

export class Sidebar extends Component {
  state = {}

  constructor (props) {
    super(props)
    this.sticky = null

    this.setStickyRef = element => this.setState({ sticky: element })

    if (!this.props.magazineCover) {
      this.props.fetchMagazineCover()
    }
  }
  render () {
    const props = this.props
    return (
      <aside className='sidebar' ref={this.setStickyRef}>
        {/* <Link to='/design-directory'><img src={JoinDirectory} alt='Join our design directory' /></Link> */}
        <div className='sidebar-search'>
          <form action='/search' method='get'>
            <input type='search' placeholder={`Search ${process.env.RAZZLE_SITE_NAME}`} name='q' id='q' />
            <button><Search /></button>
          </form>
        </div>
        <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} targeting={{ mpu: 'topright1' }} collapseEmptyDiv className='advert-sidebar' {...FORMATS.mpu} />
        <div className='on-sale-now'>
          <h3>On Sale Now</h3>
          <div>
            {this.props.magazineCover &&
              <img src={this.props.magazineCover.image} alt='magazine cover' />
            }
          </div>
          <div className='edition-subscribe'>
            <a href='http://www.anapublishing.com/subscriptions/' target='_blank' rel='noopener noreferrer'>Subscribe to the Print Edition</a>
          </div>
        </div>
        {this.props.children}
        <Desktop>
          <Sticky context={this.state.sticky}>
            <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} targeting={{ mpu600: 'right' }} collapseEmptyDiv className='advert-sidebar' {...FORMATS.mpu600} />
          </Sticky>
        </Desktop>
        <Tablet>
          <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} targeting={{ mpu600: 'right' }} collapseEmptyDiv className='advert-sidebar' {...FORMATS.mpu600} />
        </Tablet>
      </aside>
    )
  }
}
const mapStateToProps = (state) => (state.magazineCover)
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchMagazineCover }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
