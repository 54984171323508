import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { Prefetch } from '../../../store/app'
import { Articles, HomePageMeta } from 'ion-article-cmp'
import Ad from 'ion-ads'
import FORMATS from '../../components/AdFormats'
import NoImage from '../../static/no-image.png'
import HomePageTemplate from '../../templates/Articles/HomePageHighlight'
import SiteSidebar from '../../components/Sidebar'
import logo from '../../static/general/african-independent.svg'

export class HomePage extends Component {
  constructor (props) {
    super(props)
    // Don't call this.setState() here!
    this.state = { isEmpty: true, is600: false }
  }
  componentDidMount () {
    // Don't prefetch at this stage
    // this.props.Prefetch()
  }
  render () {
    const description = process.env.RAZZLE_SITE_DESCRIPTION
    return (
      <Fragment>
        <Helmet title={process.env.RAZZLE_SITE_TITLE}>
          <meta property='og:url' content={process.env.RAZZLE_SITE_URL} />
          <meta name='description' content={description} />
          <meta itemProp='description' content={description} />
          <meta property='og:description' content={description} />
          <meta name='twitter:site' content={process.env.RAZZLE_SITE_TWITTER} />
          <meta name='twitter:creator' content={process.env.RAZZLE_SITE_TWITTER_USER} />
          <meta name='twitter:description' content={description} />
          <meta name='twitter:title' content={description} />
          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:image:src' content={logo} />
        </Helmet>
        <HomePageMeta {...this.props} />
        <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/homepage`} slotId='leaderboard-top' targeting={{ leaderboard: 'top' }} collapseEmptyDiv className='advert-leaderboard-top' {...FORMATS.leaderboard} />
        <div className='wrapper article-listing' ref={this.setStickyRef}>
          <Articles {...this.props} component={HomePageTemplate} section='all-content-feed' count={18} pageSize={24} noImage={NoImage} />
          <SiteSidebar {...this.props} section='homepage' sticky={this.state.sticky} />
        </div>
        <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/homepage`} slotId='leaderboard-bot' targeting={{ leaderboard: 'bot' }} collapseEmptyDiv className='advert-leaderboard-top' {...FORMATS.leaderboard} />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({ articles: state.articles })
const mapDispatchToProps = (dispatch) => bindActionCreators({ Prefetch }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
